<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/logo/Round_logo.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div>
                  <div v-if="!getName" class="p-8 login-tabs-container">
                    <vs-alert :color="alert.color" :title="alert.title" :active="alert.active">
                      {{alert.message}}
                    </vs-alert>
                    <div>
                      <vs-input
                        name="phone"
                        icon-no-border
                        icon="icon icon-user"
                        icon-pack="feather"
                        label-placeholder="Mobile"
                        v-validate="{required:true, length:11, regex:/\0?1[3456789][0-9]{8}\b/}"
                        v-model="phone"
                        @keyup.enter="send_code"
                        class="w-full"/>
                      <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>

                      <vs-input
                        v-if="show_code"
                        type="number"
                        name="code"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        label-placeholder="Code"
                        v-validate="{required:code_required, length:4}"
                        v-model="code"
                        @keyup.enter="login"
                        class="w-full mt-6" />
                      <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>

                      <div class="flex flex-wrap justify-between my-5">
                        <!--<router-link to="/forgot-password">Forgot Password?</router-link>-->
                      </div>

                      <vs-button :disabled="counting" type="border" @click="send_code">
                        <vue-countdown v-if="counting" :time="60000" @end="endCounting">
                          <template slot-scope="props">Get again {{ props.totalSeconds }} seconds later</template>
                        </vue-countdown>
                        <span v-else>Get Verification Code</span>
                      </vs-button>
                      <vs-button v-if="show_code" class="float-right" @click="login">Login</vs-button>

                      <!--<vs-divider>OR</vs-divider>-->

                      <!--<div class="social-login-buttons flex flex-wrap items-center mt-4">-->

                        <!--&lt;!&ndash; facebook &ndash;&gt;-->
                        <!--<div class="bg-facebook pt-3 pb-2 px-4 text-white rounded-lg cursor-pointer mr-4">-->
                          <!--<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" class="text-white h-4 w-4 svg-inline&#45;&#45;fa fa-facebook-f fa-w-9" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path fill="currentColor" d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z"></path></svg>-->
                          <!--Facebook-->
                        <!--</div>-->
                        <!--&lt;!&ndash; GOOGLE &ndash;&gt;-->
                        <!--<div class="bg-google pt-3 pb-2 px-4 text-white rounded-lg cursor-pointer mr-4">-->
                          <!--<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" class="text-white h-4 w-4 svg-inline&#45;&#45;fa fa-google fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>-->
                          <!--Google-->
                        <!--</div>-->
                      <!--</div>-->
                    </div>

                  </div>
                  <div v-else class="m-2">
                    <small class="text-primary">*It will be used for future purchase.</small>
                    <vs-input  label="Full Name *" v-validate="'required'"  name="Full Name" v-model="user_data.name" class="mt-5 w-full" />
                    <span class="text-danger text-sm"  v-show="errors.has('Full Name')">{{ errors.first('Full Name') }}</span>

                    <vs-textarea
                      v-model="user_data.address"
                      v-validate="'required'"
                      name="address"
                      label="Address *"
                      placeholder="Enter full address"
                      class="w-full mt-5"/>
                    <span v-show="errors.has('address')" class="text-danger">{{ errors.first('address') }}</span>
                    <vs-button class="float-right" @click="update_data">Save</vs-button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown';
export default{
  components:{
    VueCountdown
  },
  data() {
    return {
      alert:{
        active:false,
        color:'',
        message:'',
        title:'',
      },
      user_data: this.$store.state.user,
      loginActive:false,
      phone:'',
      code:'',
      show_code:false,
      code_required:false,
      counting:false,
      checkbox_remember_me:true,
      getName:false
    }
  },
  methods: {
    update_data(){
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.$auth.user().first_name){
            this.axios.post('/auth/updateUser', {name:this.user_data.name});
          }
          this.update_user();
          this.loginActive = false;
        }
      })

    },
    send_code(){
      this.code_required = false;
      this.$validator.reset();
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          this.axios.post('/auth/get_code', {phone:this.phone}).then(({data}) =>{
            this.$vs.loading.close();
            this.$vs.notify({
              title:data.status,
              text:data.message,
              color:data.status == 'error'?'warning':'success'
            });
            if (data.status == 'success'){
              this.show_code = true;
              this.counting = true;
            }
          }).catch(error => {
            this.$vs.loading.close();
            this.alert = {
              title:'Error',
              active:true,
              color:'warning',
              message:error.message
            }
          });
        }
      })
    },
    endCounting(){
      this.counting = false;
    },
    login(){
      this.code_required = true;
      this.$validator.reset();
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading();
          var app = this;
          this.$auth.login({
            data: {
              phone: app.phone,
              code: app.code,
              remember_me: app.checkbox_remember_me,
            },
            redirect:false,
            fetchUser: true,
            rememberMe: app.checkbox_remember_me,
            error: function (error) {
              if (error.response.data.status == 'error'){
                app.alert = {
                  active:true,
                  title:error.response.data.title,
                  color:'warning',
                  message:error.response.data.message
                }
              }else{
                app.alert = {
                  active:true,
                  title:'Error',
                  color:'warning',
                  message:error.message
                }
              }
              app.$vs.loading.close();
            },
            success: function () {
              app.alert.active = false;
              var user =app.$auth.user();
              if (!user.first_name){
                app.getName = true;
              } else {
                app.user_data.phone = user.phone;
                app.user_data.name = user.first_name;
                app.update_user();
                app.loginActive = false;
              }
              app.$vs.loading.close();
              this.$router.go(-1);
            },
          });
        }
      })
    },
    show_login(){
      this.loginActive = true;
    },
    update_user(){
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.commit('UPDATE_USER', this.user_data);
        }})
    },
  }
}
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook { background-color: #1551b1 }
    .bg-twitter { background-color: #00aaff }
    .bg-google { background-color: #4285F4 }
    .bg-github { background-color: #333 }
  }
}
</style>
